<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel :height="scrollHeight">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <img class="bannerHeight" :src="item" width="100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="banner" @click="$router.push('/phantas')">
      <div class="nested-text">
        <p>{{ this.$t("home.AutoSpot") }}</p>
        <p>{{ this.$t("home.QuadruplEefficiency") }}</p>
      </div>
    </div>
    <ul class="conent">
      <li>
        <div>
          <p>Vacuum 40</p>
          <p>{{ this.$t("home.RobotWith") }}</p>
          <p class="click" @click="$router.push('/vacuum40')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Scrubber 50</p>
          <p>{{ this.$t("home.AnExper") }}</p>
          <p class="click" @click="$router.push('/scrubber50')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Scrubber 75</p>
          <p>{{ this.$t("home.CleaningOil") }}</p>
          <p class="click" @click="$router.push('/scrubber75')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Sweeper 111</p>
          <p>{{ this.$t("home.OutdoorCleaning") }}</p>
          <p class="click" @click="$router.push('/sweeper111')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
    </ul>
    <div class="footer">
      <div class="title">
        <p>その他の領域</p>
        <p>———— other areas ————</p>
      </div>
      <div>
        <ul class="digit">
          <li @click="$router.push('/tv')">
            <div>
              <p>MAXHUB</p>
              <p>{{ this.$t("home.HighQuality") }}</p>
            </div>
          </li>
          <li @click="$router.push('/robotDog')">
            <div>
              <p>Mini Pupper</p>
              <p>{{ this.$t("home.SpeechRecognition") }}</p>
            </div>
          </li>
          <li @click="$router.push('/speaker')">
            <div>
              <p>eMeet Meeting Capsule</p>
              <p>{{ this.$t("home.Degree") }}</p>
            </div>
          </li>
          <li @click="$router.push('/car')">
            <div>
              <p>{{ this.$t("car.car") }}</p>
              <p>{{ this.$t("home.FullScreen") }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图动态高度
      scrollHeight: "850px",
      // 轮播图图片
      banner: [
        require("../../assets/banner/slideshow.png"),
        require("../../assets/banner/slideshow2.png"),
        require("../../assets/banner/slideshow3.png"),
      ],
      // 商品详情图片
      commodity: [
        "../../assets/commodity/commodity (1).png",
        "../../assets/commodity/commodity (2).png",
        "../../assets/commodity/commodity (3).png",
        "../../assets/commodity/commodity (4).png",
      ],
      // other 其他领域图片
      digit: [
        "../../assets/digit/digit (1).png",
        "../../assets/digit/digit (2).png",
        "../../assets/digit/digit (3).png",
        "../../assets/digit/digit (4).png",
      ],
    };
  },
  created() {},
  mounted() {
    // 获取浏览器可视窗口计算banner的高度
    this.scrollHeight = document.body.clientWidth / (1920 / 933) + "px";
    // 浏览器视口改变，实时改变轮播图高度
    window.onresize = () => {
      return (() => {
        this.scrollHeight =
          document.querySelector(".bannerHeight").offsetHeight + "px";
        // console.log(1);
      })();
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.header {
  img {
    vertical-align: bottom;
  }
}
/deep/ .el-carousel {
  width: 100%;
  .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
.banner {
  background: url("../../assets/mobileHome/banner.png") no-repeat center;
  background-size: contain;
  height: 56vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .nested-text {
    max-width: 41%;
    height: 15.2vw;
    margin-right: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-family: "Meiryooo";
      font-weight: bold;
    }
    p:first-child {
      font-size: 12px;
      line-height: 17px;
      color: #404040;
    }
    p:last-child {
      font-size: 20px;
      line-height: 28px;
      color: #000;
      span {
        font-size: 20px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 28px;
        color: #0064e6;
      }
    }
  }
}

.conent {
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    width: 91.4667vw;
    height: 106.6667vw;
    margin-bottom: 3.2vw;
    div {
      height: 40vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10.6667vw;
      p {
        font-family: "Meiryooo";
        color: #333333;
      }
      p:first-child {
        margin-bottom: 5.3333vw;
        font-size: 7.4667vw;
        font-weight: bold;
        line-height: 10.6667vw;
      }
      p:nth-child(2) {
        font-size: 3.7333vw;
        font-weight: 400;
        line-height: 5.3333vw;
      }
      p:last-child {
        margin-top: 3.2vw;
        font-size: 12px;
        font-weight: 400;
        line-height: 4.5333vw;
        color: #0064e6;
      }
    }
  }
  li:first-child {
    background: url("../../assets/mobileHome/commodity1.png");
  }
  li:nth-child(2) {
    background: url("../../assets/mobileHome/commodity2.png");
  }
  li:nth-child(3) {
    background: url("../../assets/mobileHome/commodity3.png");
  }
  li:last-child {
    background: url("../../assets/mobileHome/commodity4.png");
    margin-bottom: 10.6667vw;
  }
}
.footer {
  .title {
    height: 15.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8vw;
    p {
      font-family: "Meiryooo";
    }
    p:first-child {
      font-size: 5.3333vw;
      font-weight: bold;
      line-height: 7.4667vw;
      color: #000000;
    }
    p:last-child {
      font-size: 3.2vw;
      font-weight: 400;
      line-height: 4.5333vw;
      color: #808695;
    }
  }
  .digit {
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      height: 58.6667vw;
      width: 91.4667vw;
      margin-bottom: 3.2vw;
      background-size: cover !important;
      div {
        padding: 4vw 5.3333vw;
        p {
          font-family: "Meiryooo";
        }
        p:first-child {
          font-size: 3.7333vw;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 22px;
          color: #000000;
          margin-bottom: 2vw;
        }

        p:last-child {
          font-size: 3.2vw;
          font-weight: 400;
          line-height: 5.0667vw;
          color: #404040;
        }
      }
    }
    li:first-child {
      background: url("../../assets/mobileHome/digit1.png") no-repeat center;
      p:last-child {
        color: #000;
      }
    }
    li:nth-child(2) {
      background: url("../../assets/mobileHome/digit2.png") no-repeat center;
    }
    li:nth-child(3) {
      background: url("../../assets/mobileHome/digit3.png") no-repeat center;
    }
    li:last-child {
      background: url("../../assets/mobileHome/digit4.png") no-repeat center;
      margin-bottom: 5.3333vw;
      p:last-child {
        color: #000;
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <!-- 轮播图 -->
    <el-carousel :height="scrollHeight">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <!-- <li class="click" @click="$router.push(item.address)">
          <img class="bannerHeight" :src="item.imgSrc" width="100%" />
        </li> -->
        <img class="bannerHeight" :src="item.imgSrc" width="100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="messageOne">
      <div class="box-content">
        <div class="box">
          <p class="title">{{ this.$t("home.AutoSpot") }}</p>
          <p class="banner-mes">{{ this.$t("home.QuadruplEefficiency") }}</p>
          <div class="text">
            <p class="text-content">
              {{ this.$t("home.PhantasExplores") }}
            </p>
          </div>
          <p class="footer-mes click" @click="$router.push('/phantas')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </div>
    </div>
    <ul class="commodity">
      <li>
        <div>
          <p>Vacuum 40</p>
          <p>{{ this.$t("home.RobotWith") }}</p>
          <p class="click" @click="$router.push('/vacuum40')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Scrubber 50</p>
          <p>{{ this.$t("home.AnExper") }}</p>
          <p class="click" @click="$router.push('/scrubber50')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Scrubber 75</p>
          <p>{{ this.$t("home.CleaningOil") }}</p>
          <p class="click" @click="$router.push('/scrubber75')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Sweeper 111</p>
          <p>{{ this.$t("home.OutdoorCleaning") }}</p>
          <p class="click" @click="$router.push('/sweeper111')">
            {{ this.$t("home.SeeMorel") }}
          </p>
        </div>
      </li>
    </ul>
    <div class="otherAreas container">
      <div class="other">
        <h3>{{ this.$t("home.OtherAreas") }}</h3>
        <h4>———— OTHER AREAS ————</h4>
      </div>
      <ul class="digit">
        <li class="click" @click="$router.push('/tv')">
          <div>
            <p>MAXHUB</p>
            <p>{{ this.$t("home.HighQuality") }}</p>
          </div>
        </li>
        <li class="click" @click="$router.push('/speaker')">
          <div>
            <p>eMeet Meeting Capsule</p>
            <p>{{ this.$t("home.Degree") }}</p>
          </div>
        </li>
        <li class="click" @click="$router.push('/robotDog')">
          <div>
            <p>Mini Pupper</p>
            <p>{{ this.$t("home.SpeechRecognition") }}</p>
          </div>
        </li>
        <li class="click" @click="$router.push('/car')">
          <div>
            <p>{{ this.$t("car.car") }}</p>
            <p></p>
            <p>{{ this.$t("home.FullScreen") }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图动态高度
      scrollHeight: "850px",
      // 轮播图图片
      banner: [
        {
          imgSrc: require("../../assets/banner/slideshow.png"),
          address: "/phantas",
        },
        {
          imgSrc: require("../../assets/banner/slideshow2.png"),
          address: "/phantas",
        },
        {
          imgSrc: require("../../assets/banner/slideshow3.png"),
          address: "/phantas",
        },
      ],
      // 商品详情图片
      commodity: [
        "../../assets/commodity/commodity (1).png",
        "../../assets/commodity/commodity (2).png",
        "../../assets/commodity/commodity (3).png",
        "../../assets/commodity/commodity (4).png",
      ],
      // other 其他领域图片
      digit: [
        "../../assets/digit/digit (1).png",
        "../../assets/digit/digit (2).png",
        "../../assets/digit/digit (3).png",
        "../../assets/digit/digit (4).png",
      ],
    };
  },
  created() {},
  mounted() {
    // 获取浏览器可视窗口计算banner的高度
    this.scrollHeight = document.body.clientWidth / (1920 / 933) + "px";
    // 浏览器视口改变，实时改变轮播图高度
    window.onresize = () => {
      return (() => {
        this.scrollHeight =
          document.querySelector(".bannerHeight").offsetHeight + "px";
        // console.log(1);
      })();
    };
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.home {
  box-sizing: border-box;
  .click {
    cursor: pointer;
  }
  /deep/ .el-carousel {
    width: 100%;
    .el-carousel__button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .el-carousel__indicators--horizontal{
      bottom:30px;
    }
  }

  .messageOne {
    position: relative;
    height: 0;
    padding-bottom: 46.875%;
    background: url("../../assets/messageOne/2@2x.png") no-repeat center;
    background-size: contain !important;
    .box-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 512px;
        height: 347px;
        p {
          font-family: 'Meiryooo';
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #404040;
        }
        .banner-mes {
          font-size: 44px;
          font-weight: bold;
          line-height: 59px;
          color: #000000;
        }
        .text-content {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #404040;
        }
        .footer-mes {
          font-family: 'Meiryooo';
          font-weight: bold;
          line-height: 19px;
          color: #0064e6;
        }
      }
    }
  }

  .commodity {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    li {
      margin-top: 15px;
      width: 48.5%;
      min-width: 375px;
      height: 470px;
      background-size: contain;
      text-align: center;
      div {
        height: 162px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        P {
          font-family: 'Meiryooo';
        }
        P:first-child {
          font-size: 28px;
          font-weight: bold;
          line-height: 37px;
          color: #333333;
        }
        P:nth-child(2) {
          font-size: 18px;
          font-weight: 400;
          line-height: 0.24rem;
          color: #333333;
        }
        P:last-child {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #0064e6;
        }
      }
    }
    li:first-child {
      background: url("../../assets/commodity/commodity1.png") no-repeat center;
    }
    li:nth-child(2) {
      background: url("../../assets/commodity/commodity2.png") no-repeat center;
    }
    li:nth-child(3) {
      background: url("../../assets/commodity/commodity3.png") no-repeat center;
    }
    li:last-child {
      background: url("../../assets/commodity/commodity4.png") no-repeat center;
    }
  }
  .otherAreas {
    display: flex;
    flex-direction: column;
    align-items: center;
    .other {
      margin: 100px 0 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        margin-bottom: 30px;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        color: #000;
        line-height: 52px;
      }
      h4 {
        font-size: 20px;
        line-height: 23px;
        color: #808695;
      }
    }
    .digit {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // align-content: space-between;
      width: 1200px;
      height: 900px;
      margin-bottom: 90px;
      li {
        margin-bottom: 40px;
        background-size: contain !important;
        height: 430px !important;
        div {
          height: 66px;
          margin-top: 40px;
          margin-left: 40px;
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Meiryooo';
            color: #000000;
          }
          p:first-child {
            margin-bottom: 12px;
            font-size: 26px;
            font-weight: bold;
            line-height: 35px;
          }
          p:last-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }
      li:first-child {
        width: 730px;
        background: url("../../assets/digit/digit1.png") no-repeat center;
      }
      li:nth-child(2) {
        width: 430px;
        background: url("../../assets/digit/digit2.png") no-repeat center;
      }
      li:nth-child(3) {
        width: 430px;
        background: url("../../assets/digit/digit3.png") no-repeat center;
      }
      li:last-child {
        width: 730px;
        background: url("../../assets/digit/digit4.png") no-repeat center;
      }
    }
  }

  // / 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .commodity {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      li {
        aspect-ratio: calc(930 / 470);
        margin-top: 2vw;
        width: 100%;
        min-width: 375px;
        height: 0;
        padding-bottom: 50%;
        background-size: contain !important;
        text-align: center;
        div {
          height: 21.6vw;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          P {
            font-family: 'Meiryooo';
          }
          P:first-child {
            font-size: 0.28rem;
            font-weight: bold;
            line-height: 0.37rem;
            color: #333333;
          }
          P:nth-child(2) {
            font-size: 2.4vw;
            font-weight: 400;
            line-height: 3.2vw;
            color: #333333;
          }
          P:last-child {
            font-size: 2.1333vw;
            font-weight: 400;
            line-height: 2.8vw;
            color: #0064e6;
          }
        }
      }
      li:first-child {
        background: url("../../assets/commodity/commodity1.png") no-repeat
          center;
      }
      li:nth-child(2) {
        background: url("../../assets/commodity/commodity2.png") no-repeat
          center;
      }
      li:nth-child(3) {
        background: url("../../assets/commodity/commodity3.png") no-repeat
          center;
      }
      li:last-child {
        background: url("../../assets/commodity/commodity4.png") no-repeat
          center;
      }
    }
    .digit {
      li {
        p {
          transform: scale(0.8);
          transform-origin: left;
        }
      }
    }
  }

  // 当屏幕宽度小于860px时
  // @media screen and (max-width: 860px) {
  //   .otherAreas {
  //     .other {
  //       margin: 50px 0 20px 0;
  //     }
  //     .digit {
  //       margin-bottom: 0;
  //       padding: 20px;
  //       height: unset;
  //       justify-content: center;
  //       img {
  //         margin-bottom: 20px;
  //         width: 100%;
  //         height: unset !important;
  //       }
  //     }
  //   }
  // }
}
//浏览器最小字体12px 当屏幕小于900时，messageOne区域排版较乱-查询修改行高以及缩放
@media screen and (max-width: 1200px) {
  .box {
    .text {
      .text-content {
        width: 50vw;
        line-height: 1.2 !important;
        font-size: 2.1333vw;
        transform: scale(0.8);
        transform-origin: left;
      }
    }
    .footer-mes {
      transform: scale(0.6);
      transform-origin: left;
    }
  }
  .digit {
    // font-size: ;
    li {
      p {
        line-height: 1.2 !important;
      }
    }
  }
}
</style>
